import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Grid, Box, Text, Button, Image } from "theme-ui"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Breadcrumbs from "../components/Breadcrumbs"
import LinkCard from "../components/LinkCard"

const ShowsPage = () => {
  const data = useStaticQuery (
    graphql`
      query {
        allContentfulShow(sort: { fields: createdAt, order: DESC }) {
          edges {
            node {
              title
              description {
                childMdx {
                  rawBody
                }
              }
              image {
                file {
                  url
                }
              }
              url
              type
              featured
              createdAt
            }
          }
        }
      }
    `
  )
  
  return (
    <Layout>
      <SEO title="Shows" />

      <Breadcrumbs title="Shows" headline="Great adventures, now playing" />

      <Grid variant="textWrap">
        <Text as="p" variant="bodyLarge">Quest is catching on with streamers and roleplayers, and we're excited to share some of our favorite real-play shows.</Text>
      </Grid>

      {data.allContentfulShow.edges.map(edge => {
        return (
          <>
            {edge.node.featured &&
              <Grid variant="base" columns={[1, "1fr 3fr"]} gap={[4, 6]} sx={{ mt: [4, 6], alignItems: "center", bg: "grayXLight", p: [4, 6] }}>
                <Image src={edge.node.image.file.url} />
                
                <Box>
                  <Text as="p" variant="label" sx={{ mb: 1 }}>Featured Show</Text>
                  <Text as="h2" variant="title01" sx={{ fontFamily: "display", textTransform: "none" }}>{edge.node.title}</Text>
                  <Text as="p" variant="bodyLarge">{edge.node.description.childMdx.rawBody}</Text>
                  <a href={edge.node.url} target="_blank" rel="noreferrer">
                    <Button variant="secondary">{edge.node.type === true ? "Listen Now" : "Watch Now"}</Button>
                  </a>
                </Box>
              </Grid>
            }
          </>
        )
      })}

      <Grid variant="base" columns={[1, 2, 3]} sx={{ gridGap: 6, mt: [7, 8] }}>
        {data.allContentfulShow.edges.map(edge => {
          return (
            <>
              {edge.node.featured ?
                null
                :
                <LinkCard
                  title={edge.node.title}
                  body={edge.node.description.childMdx.rawBody}
                  image={
                    <Image src={edge.node.image.file.url} sx={{ display: "block", mb: 3, width: "100%", height: "auto" }} />
                  }
                  buttonLabel={edge.node.type === true ? "Listen Now" : "Watch Now"}
                  url={edge.node.url} />
              }
            </>
          )
        })}
      </Grid>
    </Layout>
  )
}

export default ShowsPage